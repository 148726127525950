"use client";

import React from 'react';
import Styles from './RecentlyViewed.module.scss';
import utils from 'styles/globals/utils.module.scss';
import { useNostoRecommendations } from 'thirdparty/Nosto/hooks/useNostoRecommendations';
import RecommendedProducts from '../RecommendedProducts';

const RecentlyViewed = ({ searchQuery }) => {
  const nostoId = process.env.NEXT_PUBLIC_nosto_typeahead_recently_id;
  const recommendations = useNostoRecommendations(nostoId);

  if (!recommendations?.primary?.length) {
    return null;
  }

  const items = recommendations.primary.map(({ name, imageUrl, productId, price, url }) => {

    const trimmedUrl = url.replace(/\/$/, '');
    const parts = trimmedUrl.split('/');
    const urlAux = parts[parts.length - 1];

    return {
      name,
      image_1: imageUrl,
      primary_image: imageUrl,
      product_id: productId,
      price,
      trade_price: price,
      url: urlAux,
    };
  }).slice(0, 4);

  return (
    <>
      <h6 className={utils.fs_6}>Recently Viewed</h6>
      <RecommendedProducts products={items} searchQuery={searchQuery} useCloudinaryImage={false} />
    </>
  );
};

export default RecentlyViewed;
