import Styles from './RecommendedProducts.module.scss';

import useDebounce from 'react-use/lib/useDebounce';
import { useState, useEffect } from 'react';
import GetSearchRecommendations from 'thirdparty/Nosto/queries/GetSearchRecommendations';
import Image from "next/image"
import ProductPrice from 'components/product/ProductPrice';
import Link from 'next/link';
import envUrl from 'lib/Utils/envUrl';
import NostoGraphql from 'thirdparty/Nosto/Graphql.service';
import { cloudinaryProductImageLoader } from 'thirdparty/Cloudinary/cloudinaryLoader';
import { blurImage } from 'lib/Constants/blurImage';
import getFileName from 'lib/Utils/getFileName';


const nostoGraphql = new NostoGraphql()


function RecommendedProducts({ products = [], searchQuery, isTrade, useCloudinaryImage=true }) {
    const [ query, setQuery ] = useState("")
    const [ items, setItems ] = useState([])
    const [ _, cancel ] = useDebounce(() => {
        setQuery(searchQuery)

    }, 500, [searchQuery])


    useEffect(() => {
        if( !products ){
            const searchRecommendation = GetSearchRecommendations(query)

            nostoGraphql.post(searchRecommendation).then(response => {
                const responseData = response?.data?.recos?.q_related.primary
                setItems(responseData)
            })
        }

    }, [query, products])


    return (
        <>
           {products.length || items.length ? (
                <div className={Styles.block}>
                                   
                    <ul className={Styles.row}>
                        {products?.length ? 
                            <>
                                {products.map((item, index) => {
                                    if( index > 3){ return null }

                                    return(
                                        <Item 
                                            key={`item_${index}`}
                                            {...item}
                                            {...{ isTrade, useCloudinaryImage }}
                                        />
                                    )
                                })}
                                
                            </>
                        :
                        
                            <>
                                {items.map((item, i) => {
                                    return(
                                        <Item 
                                            key={`item_${i}`} 
                                            {...item} 
                                            {...{ isTrade, useCloudinaryImage }}
                                        />
                                    )
                                })}
                            </>
                        
                        }
                    </ul>
                </div>
           ):null}
        </>
        
    );
}




function Item({ 
    imageUrl, 
    primary_image, 
    trade_price, 
    isTrade = false, 
    name, 
    price, 
    url, 
    useCloudinaryImage, 
    minimum_order_qty_per_unit,
    ...other 
}){
    const newUrl = envUrl(url)
    const [ fileName, setFileName ] = useState(blurImage)


    useEffect(() => {
        const fileName = primary_image || imageUrl ? getFileName(primary_image ?? imageUrl) : null;
        setFileName(fileName)
    }, [
        primary_image,
        imageUrl
    ])

    return (
        <li className={Styles.item}>
            <Link href={`/${newUrl}`} title={name} className={Styles.link}>
                <Image 
                    src={useCloudinaryImage ? fileName : primary_image}
                    alt={name}
                    width="100"
                    height="100" 
                    className={Styles.image}
                    placeholder="blur"
                    blurDataURL={blurImage}
                    loader={useCloudinaryImage ? cloudinaryProductImageLoader : false}
                />

                <div className={Styles.details}>
                    <p 
                        className={Styles.title} 
                        dangerouslySetInnerHTML={{__html: name}} 
                    />

                    {price !== "0" && (
                        <div className={Styles.price}>
                            <ProductPrice 
                                price={isTrade ? trade_price : price}
                            />

                            {minimum_order_qty_per_unit && (
                                <small className={Styles.perUnit}>
                                    /per {minimum_order_qty_per_unit}
                                </small>
                            )}
                        </div>
                    )}
                </div>
            </Link>
        </li>
    );
}





export default RecommendedProducts;