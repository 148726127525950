"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import { useHSContext } from 'components/applications/header_footer/Header/contexts/HeaderSearchContext';
import ResultsList from '../ResultsList';
import RecommendedProducts from '../RecommendedProducts';
import FeaturedAd from '../FeaturedAd';
import ViewMore from '../ViewMore';
import RecentlyViewed from '../RecentlyViewed';
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Styles from './Results.module.scss';
import { usePathname } from 'next/navigation';
import RecommendedContent from '../RecommendedContent';
import useDebounce from 'react-use/lib/useDebounce';

function Results({ isInFocus, isTrade, query }) {
    const { searchQuery, useAutoComplete } = useHSContext();
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
    const { data, isError, isLoading } = useAutoComplete(debouncedSearchQuery, isInFocus);
    const asPath = usePathname();
    const node = useRef();
    const [ open, shouldOpen ] = useState(false)
    const [ resultsInFocus, setResultsInFocus ] = useState(false)


    useDebounce(() => {
        setDebouncedSearchQuery(searchQuery);
    }, 200, [searchQuery]);


    //handle page click when clicked in any link
    const handleLinkClick = e => {
        if (e.target.tagName === 'A') {
            shouldOpen(false);
        }
    };

    useEffect(() => {
        if (query && (
                (data?.products?.querySuggestions?.length && isInFocus) 
            ||  (data?.products?.data?.length && isInFocus) 
            ||  (data?.content_feed?.data?.length && isInFocus) 
            ||  (data?.products?.data?.length && resultsInFocus) 
            ||  (data?.content_feed?.data?.length && resultsInFocus)
        )){
            shouldOpen(true);
        } else {
            shouldOpen(false);
        }

        node.current?.addEventListener('click', handleLinkClick);

        return () => {
            node.current?.removeEventListener('click', handleLinkClick);
        };


    }, [ asPath, data, isInFocus, query ])





    return (
        <div 
            className={clsx(Styles.block, open && Styles.show)} 
            ref={node} 
            onMouseEnter={() => setResultsInFocus(true)}
            onMouseLeave={() => setResultsInFocus(false)}
            tabIndex={0}
        >

            <ResultsList
                searchQuery={searchQuery}
                searchList={data?.products?.querySuggestions}
                loadingList={false}
                noList={data?.products?.querySuggestions?.length === 0}
            />

            <ViewMore
                searchQuery={searchQuery}
                totalResults={data?.products?.pagination?.totalResults}
            />

            <RecommendedProducts
                products={data?.products?.data}
                searchQuery={searchQuery}
                {...{ isLoading, isError, isTrade }}
            />

            <ViewMore
                searchQuery={searchQuery}
                totalResults={data?.content_feed?.pagination?.totalResults}
                label="content"
                showLink={false}
            />
            
            <RecentlyViewed 
                searchQuery={searchQuery}
                />
        </div>
    )
}

export default React.memo(Results);